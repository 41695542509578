import React, { useState, useEffect } from "react";
import axios from "./api/axiosConfig";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Home from "./Pages/Home/Home";
import Recommendation from "./Pages/Recommendation/Recommendation";
import HandleNulls from "./Pages/HandleNulls/HandleNulls";
import Sidebar from "./Sidebar/Sidebar";
import CompareTables from "./Pages/CompareTables/CompareTables";
import InsertandEdits from "./Pages/InsertandEdits/InsertandEdits";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css"; // You can use this to add styles to your app layout
import { withAuthenticator } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { Hub } from "aws-amplify/utils";
import "@aws-amplify/ui-react/styles.css";
import awsmobile from "./aws-exports";
import AdminDashboard from "./Pages/AdminDashboard/AdminDashboard";
import News from "./Pages/News/News";
import Notifications from "./Pages/Notifications/Notifications";

Amplify.configure(awsmobile);

Hub.listen("auth", (data) => {
  const { payload } = data;
  if (payload.event === "signedIn") {
    axios.post("/admin/login");
  }
});

function App() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };
  return (
    <Router>
      <PageTitleUpdater />
      <div className="App">
        <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
        <div className="content">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/AdminDashboard" element={<AdminDashboard />} />
            <Route path="/Recommendation" element={<Recommendation />} />
            <Route path="/HandleNulls" element={<HandleNulls />} />
            <Route path="/compareTables" element={<CompareTables />} />
            <Route path="/InsertandEdits" element={<InsertandEdits />} />
            <Route path="/News" element={<News />} />
            <Route path="/Notifications" element={<Notifications />} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

// Component to update the page title based on the route
function PageTitleUpdater() {
  const location = useLocation();

  useEffect(() => {
    const pageTitles = {
      "/": "Home",
      "/AdminDashboard": "Admin Dashboard",
      "/Recommendation": "Asset Recommendations",
      "/HandleNulls": "Handling Nulls",
      "/compareTables": "Compare Tables",
      "/InsertandEdits": "Inserts and Edits",
      "/News": "News",
      "/Notifications": "Notifications",
    };

    const pageTitle = pageTitles[location.pathname] || "AssessWorth Admin";
    document.title = pageTitle;
  }, [location]);

  return null; // This component doesn't render anything
}

export default withAuthenticator(App, { hideSignUp: true });
