import React, { useState } from "react";
import {
  Card,
  CardMedia,
  CardContent,
  Typography,
  CardActions,
  Button,
  Collapse,
  Chip,
  Box,
  Grid,
} from "@mui/material";

const NewsCard = ({ newsItem }) => {
  const [showSymbols, setShowSymbols] = useState(false);

  const toggleSymbols = () => {
    setShowSymbols(!showSymbols);
  };

  return (
    <Card sx={{ maxWidth: 345 }}>
      {/* Cover Photo */}
      <CardMedia
        sx={{ height: 200 }}
        image={newsItem.image || "/placeholder.jpg"}
        title={newsItem.title}
      />

      {/* Card Content */}
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          <a
            href={newsItem.url}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            {newsItem.title}
          </a>
          <Typography variant="body2" color="text.secondary">
            {" "}
            ({newsItem.publish_date})
          </Typography>
        </Typography>

        <Typography variant="body2" color="text.secondary">
          {newsItem.description}
        </Typography>

        <Typography
          variant="subtitle2"
          color="text.secondary"
          sx={{ marginTop: 1 }}
        >
          Source: {newsItem.author}
        </Typography>
      </CardContent>

      <CardActions>
        <Button
          variant="contained"
          sx={{ backgroundColor: "black", color: "white" }}
          size="small"
          onClick={toggleSymbols}
        >
          View Symbols
        </Button>
      </CardActions>

      <Collapse in={showSymbols} timeout="auto" unmountOnExit>
        <Box sx={{ padding: 2 }}>
          <Grid container spacing={1} sx={{ flexWrap: "wrap" }}>
            {newsItem.news_symbols.map((symbolItem, index) => (
              <Grid item xs="auto" key={index}>
                <SymbolBox symbolItem={symbolItem} />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Collapse>
    </Card>
  );
};

const SymbolBox = ({ symbolItem }) => {
  const [showDescription, setShowDescription] = useState(false);

  const getSentimentColor = (sentiment) => {
    switch (sentiment) {
      case "positive":
        return "success";
      case "negative":
        return "error";
      default:
        return "default";
    }
  };

  return (
    <Box sx={{ margin: "4px" }}>
      <Chip
        label={symbolItem.symbol_symbol.name}
        color={getSentimentColor(symbolItem.sentiment)}
        onClick={() => setShowDescription(!showDescription)}
        sx={{ borderRadius: 2, cursor: "pointer" }}
      />
      {showDescription && (
        <Typography
          variant="body2"
          color="text.secondary"
          sx={{ marginTop: 1 }}
        >
          {symbolItem.description}
        </Typography>
      )}
    </Box>
  );
};

export default NewsCard;
