// src/components/Loader.js
import React from "react";
import "./Loader.css"; // Add CSS for styling the loader

const Loader = () => (
  <div className="loader">
    <div className="spinner"></div>
  </div>
);

export default Loader;
