import React, { useState, useEffect, useMemo } from "react";
import { Modal, Table, Button } from "react-bootstrap";
import {
  columnsMap,
  dates,
  numericals,
  tables,
  tableNames,
} from "./../Models/tablesAndColumns";
import { fetchDistinctNames } from "../Models/distinctQuery";
import SymbolSearch from "./SymbolSearch";

const InsertModal = ({
  show,
  handleClose,
  tableTitle,
  selectedSymbol,
  handleFileChange,
  handleInputChange,
  handleInsertRow,
}) => {
  // Memoize the columns to avoid unnecessary recalculations
  const columns = useMemo(() => columnsMap[tableTitle] || [], [tableTitle]);

  const [options, setOptions] = useState({});

  useEffect(() => {
    const fetchOptions = async () => {
      const newOptions = {};

      for (const column of columns) {
        if (tables.includes(column)) {
          const data = await fetchDistinctNames(column);
          if (data?.data) {
            newOptions[column] = data.data;
          }
        }
      }

      setOptions(newOptions);
    };

    fetchOptions();
  }, [columns]); // Fetch options whenever columns change

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Insert into {tableTitle} {selectedSymbol ? `(${selectedSymbol})` : ""}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Column</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {columns.map((column, index) => (
              <tr key={index}>
                <td>{column}</td>
                <td>
                  {column === "icon" ? (
                    <input
                      type="file"
                      accept="image/jpeg, image/png"
                      onChange={(e) => handleFileChange(e)}
                    />
                  ) : dates.includes(column) ? (
                    <input
                      type="datetime-local"
                      onChange={(e) =>
                        handleInputChange(column, e.target.value)
                      }
                    />
                  ) : column === "name" && tableTitle === "symbol" ? (
                    <input
                      type="text"
                      placeholder="Ticker e.g., AAPL, UBA, TSLA, etc."
                      onChange={(e) =>
                        handleInputChange(column, e.target.value)
                      }
                    />
                  ) : column === "period" && tableTitle === "financials" ? (
                    <select
                      className="form-control"
                      onChange={(e) =>
                        handleInputChange(column, e.target.value)
                      }
                    >
                      <option value="">Select an option</option>
                      <option value="FY">Full Year</option>
                      <option value="TTM">Trailing Twelve Months</option>
                    </select>
                  ) : numericals.includes(column) ? (
                    <input
                      type="number"
                      onChange={(e) =>
                        handleInputChange(column, e.target.value)
                      }
                    />
                  ) : column === "symbol" ? (
                    <SymbolSearch
                      column={column}
                      handleInputChange={handleInputChange}
                    />
                  ) : tables.includes(column) ? (
                    <select
                      className="form-control"
                      onChange={(e) =>
                        handleInputChange(column, e.target.value)
                      }
                    >
                      <option value="">Select {tableNames[column]}</option>
                      {options[column]?.map((row) => (
                        <option key={row.id} value={row.id}>
                          {row.name}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <input
                      type="text"
                      onChange={(e) =>
                        handleInputChange(column, e.target.value)
                      }
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <Button variant="dark" className="mt-3" onClick={handleInsertRow}>
          Insert Row
        </Button>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InsertModal;
