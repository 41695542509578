// import axios from "../../api/axiosConfig";

// let columnsMap = {};

// const fetchColumnsMap = async () => {
//   try {
//     const response = await axios.get("/insert/get-table-columns");
//     columnsMap = response.data.data;
//   } catch (error) {
//     console.error("Error fetching columns map:", error);
//   }
// };

// fetchColumnsMap();

// Define columns based on subjectTableSelection
const columnsMap = {
  asset_type: ["name"],
  broker: ["name", "country", "icon", "website", "description"],
  company_information: ["symbol", "name", "sic_code", "icon", "description"],
  country: ["name", "abv", "icon"],
  currency: ["name", "abv", "country"],
  daily_prices: ["date", "symbol", "price"],
  dividend: ["symbol", "pay_date", "dividend"],
  exchange: ["name", "abv", "country", "icon", "website", "description"],
  financials: [
    "symbol",
    "filling_date",
    "year",
    "period",
    "eps",
    "current_assets",
    "current_liabilities",
    "total_assets",
    "total_liabilities",
    "revenue",
    "net_income_loss",
    "net_cash_flow",
    "market_cap",
    "outstanding_shares",
  ],
  investor_type: ["name", "description"],
  news: [
    "submission_date",
    "publish_date",
    "title",
    "url",
    "logo",
    "description",
  ],
  news_symbol: ["news", "symbol"],
  notification: ["date", "content", "url", "logo"],
  notification_symbol: ["notification", "symbol"],
  occupation: ["name"],
  rate_type: ["name", "description"],
  rates: ["rate_type", "country", "date", "rate"],
  recommendation_type: ["name", "description"],
  sic_code: ["sic_code", "office", "industry", "description"],
  symbol: ["name", "asset_type", "exchange", "country"],
};

const dates = [
  "date",
  "pay_date",
  "filling_date",
  "submission_date",
  "publish_date",
  "date",
  "dob",
];

const numericals = [
  "affected_asset",
  "ratings",
  "year",
  "price",
  "dividend",
  "eps",
  "current_assets",
  "current_liabilities",
  "total_assets",
  "total_liabilities",
  "revenue",
  "net_income_loss",
  "net_cash_flow",
  "market_cap",
  "outstanding_shares",
];

const tables = [
  "country",
  "sic_code",
  "currency",
  "broker",
  "exchange",
  "asset_type",
  "recommendation_type",
  "rate_type",
];

const tableNames = {
  country: "Country",
  sic_code: "Industry",
  currency: "Currency",
  broker: "Brokerage Firm",
  exchange: "Stock Exchange",
  asset_type: "Asset Class",
  recommendation_type: "Recommendation",
  symbol: "Asset Symbol",
  rate_type: "Type of Rate",
};

const others = [
  "country",
  "symbol",
  "sic_code",
  "news",
  "currency",
  "broker",
  "exchange",
  "status",
  "type",
  "period",
];

const excludeBulk = [
  "asset_type",
  "investor_type",
  "recommendation_type",
  "rate_type",
];

export {
  columnsMap,
  dates,
  numericals,
  tables,
  tableNames,
  excludeBulk,
  others,
};

/*
SELECT COLUMN_NAME, TABLE_NAME
FROM INFORMATION_SCHEMA.COLUMNS
WHERE TABLE_NAME IN (SELECT TABLE_NAME AS name
          FROM INFORMATION_SCHEMA.TABLES
          WHERE TABLE_TYPE = 'BASE TABLE' AND TABLE_SCHEMA= '') -- insert database name here
          AND COLUMN_NAME != 'id'
          AND DATA_TYPE = ''; -- run without this line to get all or run next query
          
SELECT DISTINCT(DATA_TYPE)
FROM INFORMATION_SCHEMA.COLUMNS
WHERE TABLE_NAME IN (SELECT TABLE_NAME AS name
          FROM INFORMATION_SCHEMA.TABLES
          WHERE TABLE_TYPE = 'BASE TABLE' AND TABLE_SCHEMA= '')
          AND COLUMN_NAME != 'id';
          
          
/*
datetime
varchar
int
text
date
decimal
enum
*/
