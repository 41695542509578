// src/api/axiosConfig.js
import axios from "axios";
import { fetchAuthSession } from "aws-amplify/auth";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_AXIOS_BASE_URL,
});

// Add a request interceptor to include the token
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const authToken = (await fetchAuthSession()).tokens?.idToken?.toString();
      config.headers.Authorization = `Bearer ${authToken}`;
    } catch (error) {
      console.error("Error getting token", error);
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
