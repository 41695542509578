import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { ToastContainer, toast } from "react-toastify";
//import { Modal, Button, Table } from "react-bootstrap"; // Import Bootstrap components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import DynamicTable from "./../../components/MUI Table/DynamicTable";
import "./../../components/MUI Table/DynamicTable.css";
import InsertModal from "./../../components/Popups/InsertRow";

function Recommendation() {
  const [loading, setLoading] = useState(false); // State for loading
  const [symbol, setsymbol] = useState(null); // State for loading
  const [years, setyears] = useState(null); // State for loading
  const [fullData, setfullData] = useState({}); // State for loading
  const [dataName, setdataName] = useState(null); // State for loading
  const [selectedSymbolToView, setselectedSymbolToView] = useState(null); // State for loading
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [formData, setFormData] = useState({}); // State for form data
  const [selectedFile, setSelectedFile] = useState(null);

  useEffect(() => {
    // const response = axios.get(`/recommendation/get-assets`);
    // if (response.status === 200) {
    //   // Check for a successful response
    //   setnames(response.data.data);
    // } else {
    //   toast.error(`Unexpected response code: ${response.status}`);
    // }
  }, []);

  const handleFetchData = async () => {
    try {
      setfullData({});
      setdataName(null);
      setLoading(true);
      if (!years || years === "" || !symbol || symbol === "") {
        return toast.error(`Ensure the symbol and years fields are inputted`);
      }
      if (typeof symbol !== "string" || !isNaN(Number(symbol))) {
        return toast.error(`The symbol must be a string`);
      }
      if (typeof years !== "number" || years < 1 || years > 10) {
        return toast.error(`The year must be a number between 0 and 10`);
      }
      const response = await axios.get(`/recommendation/recommendation-table`, {
        params: {
          selectedStock: symbol.toUpperCase(),
          years,
        },
      });

      if (response.status === 200) {
        // Check for a successful response
        setfullData(response.data.data);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  // Update handleInsertClick to set columns
  const handleInsertRowButton = async () => {
    setFormData({});
    setShowInsertModal(true);
  };

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
    setSelectedFile(null);
  };

  const handleInputChange = (column, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [column]: value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type;
      const validImageTypes = ["image/jpeg", "image/png"];

      if (!validImageTypes.includes(fileType)) {
        toast.error("Please upload a valid image file (JPEG or PNG).");
        setSelectedFile(null);
        return;
      }
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleInsertRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      form.append(
        "selectedTable",
        dataName === "extraPriceData" ? "daily_prices" : "financials"
      );
      form.append("symbol", symbol);
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      // Handle file input separately
      if (selectedFile && selectedFile instanceof File) {
        form.append("file", selectedFile);
      }

      const response = await axios.post("/insert/insert-single", form, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        toast.success("Data inserted successfully");

        await handleFetchData();
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error inserting row");
      console.error("Error inserting data", error);
    } finally {
      setShowInsertModal(false);
      setSelectedFile(null);
      setLoading(false);
    }
  };

  const handleEditTable = () => {
    //
    //
  };

  return (
    <div className="container mt-5">
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <div className="row equal-height">
        {/* Base Table Box */}
        <div className="col-md-6 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">Make Request</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <input
                    className="form-control"
                    type="text"
                    value={symbol || ""}
                    placeholder="symbol"
                    onChange={(e) => setsymbol(e.target.value.toUpperCase())}
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    value={years || ""}
                    className="form-control"
                    placeholder="Number of years"
                    min={1}
                    max={10}
                    onChange={(e) => setyears(Number(e.target.value))}
                    disabled={!symbol || symbol === ""}
                  />
                </div>
                <div className="col-md-4">
                  <button
                    className="btn btn-dark"
                    onClick={() => {
                      setselectedSymbolToView(symbol.toUpperCase());
                      handleFetchData();
                    }}
                    disabled={!years || years === ""}
                  >
                    Fetch
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Subject Table Box */}
        <div className="col-md-6 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">Handle Table</h4>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-4">
                  <select
                    className="form-control"
                    value={dataName || ""}
                    onChange={(e) => setdataName(e.target.value)}
                    disabled={Object.keys(fullData).length < 1}
                  >
                    <option value="">Select a Table</option>
                    <option value="generalData">General Data</option>
                    <option value="extraPriceData">Extra Prices</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <button
                    className="btn btn-dark"
                    onClick={handleEditTable}
                    disabled={!dataName || dataName === ""}
                  >
                    Edit Table
                  </button>
                </div>
                <div className="col-md-4">
                  <button
                    className="btn btn-dark"
                    onClick={handleInsertRowButton}
                    disabled={!dataName || dataName === ""}
                  >
                    Insert Row
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Table for Asset Recommendation */}
      <div className="row equal-height">
        <div className="col-md-12 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">
                Data View for Asset Recommendation{" "}
                {selectedSymbolToView
                  ? `(Symbol: ${selectedSymbolToView})`
                  : ""}
              </h4>
            </div>
            <div className="card-body">
              <div className="table-container">
                <DynamicTable
                  className="dynamic-table"
                  data={fullData[dataName]}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Insert Modal Popup */}
      <div>
        <InsertModal
          show={showInsertModal}
          handleClose={handleInsertModalClose}
          tableTitle={
            dataName === "extraPriceData" ? "daily_prices" : "financials"
          }
          selectedSymbol={symbol}
          handleFileChange={handleFileChange}
          handleInputChange={handleInputChange}
          handleInsertRow={handleInsertRow}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default Recommendation;
