import React, { useState, useEffect } from "react";
import axios from "./../../api/axiosConfig";
import { FaSearch } from "react-icons/fa";

const SymbolSearch = ({ handleInputChange, column }) => {
  const [query, setQuery] = useState("");
  const [filteredSymbols, setFilteredSymbols] = useState([]);

  useEffect(() => {
    if (query.length === 0) {
      setFilteredSymbols([]);
    }
  }, [query]);

  const fetchSymbols = async (searchTerm) => {
    try {
      const response = await axios.get(`/compare/get-symbols`, {
        params: { query: searchTerm },
      });
      setFilteredSymbols(response.data);
    } catch (error) {
      console.error("Error fetching symbols:", error);
    }
  };

  const handleSearch = () => {
    if (query.length > 0) {
      fetchSymbols(query);
    } else {
      setFilteredSymbols([]);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSelect = (id) => {
    const selectedSymbol = filteredSymbols.find((symbol) => symbol.id === id);
    handleInputChange(column, id);
    setQuery(selectedSymbol.ticker); // Set the ticker in the input field
    setFilteredSymbols([]);
  };

  return (
    <div style={{ position: "relative" }}>
      <input
        type="text"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        placeholder="Search by name or ticker"
        style={{ width: "100%", padding: "8px" }}
      />
      <button
        onClick={handleSearch}
        style={{
          background: "none",
          border: "none",
          position: "absolute",
          right: "8px",
          top: "8px",
          cursor: "pointer",
        }}
      >
        <FaSearch />
      </button>
      {filteredSymbols.length > 0 && (
        <ul
          style={{
            position: "absolute",
            top: "100%",
            left: 0,
            right: 0,
            maxHeight: "200px",
            overflowY: "auto",
            border: "1px solid #ccc",
            backgroundColor: "#fff",
            listStyleType: "none",
            padding: 0,
            margin: 0,
            zIndex: 1000,
          }}
        >
          {filteredSymbols.map((symbol) => (
            <li
              key={symbol.id}
              onClick={() => {
                handleSelect(symbol.id);
                setFilteredSymbols([]);
              }}
              style={{
                padding: "10px",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid #ddd",
              }}
            >
              <img
                src={symbol.exchange || "/exchange.png"}
                alt="Exchange"
                style={{ width: "20px", marginRight: "8px" }}
              />
              <img
                src={symbol.country || "/Earth.png"}
                alt="Country"
                style={{ width: "20px", marginRight: "8px" }}
              />
              <img
                src={
                  symbol.icon ||
                  (symbol.asset_class === "Equity"
                    ? "/Equity.png"
                    : symbol.asset_class === "Bond"
                    ? "/Bond.png"
                    : "/AssessWorth-Logo.png")
                }
                alt="Asset Icon"
                style={{ width: "20px", marginRight: "8px" }}
              />
              {`${symbol.ticker}: ${symbol.name} (${symbol.asset_class})`}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SymbolSearch;
