import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { Modal, Button, Card, Table } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  columnsMap,
  dates,
  numericals,
  tables,
  tableNames,
} from "./../../components/Models/tablesAndColumns";
import { fetchDistinctNames } from "./../../components/Models/distinctQuery";

const HandleNulls = () => {
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedTable, setSelectedTable] = useState(null);
  const [loading, setLoading] = useState(true); // State for loading
  const [showNewModal, setShowNewModal] = useState(false);
  const [newModalData, setNewModalData] = useState(null);
  const [rowsData, setRowsData] = useState(null);
  const [showRowsModal, setShowRowsModal] = useState(false);
  const [tableValue, settableValue] = useState(null);
  const [columValue, setcolumValue] = useState(null);
  const [symbolValue, setsymbolValue] = useState(null);
  const [inputValue, setInputValue] = useState(""); // Single input value
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get("/null/count-nulls"); // Replace with your actual endpoint
      if (response.status === 200) {
        // Check for a successful response
        setData(response.data.data);
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleViewMore = (table) => {
    setSelectedTable(table);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  const handleViewMoreColumn = async (tableName, columnName) => {
    try {
      settableValue(tableName);
      setcolumValue(columnName);
      setLoading(true);
      const response = await axios.get(`/null/count-nulls-symbols`, {
        params: {
          selectedTable: tableName,
          selectedColumn: columnName,
        },
      });
      setNewModalData(response.data.data); // Assuming the data is in response.data.data
      setShowNewModal(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error fetching data", error);
      }
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleCloseNewModal = () => {
    setShowNewModal(false);
    setNewModalData(null);
  };

  const handleViewRows = async (stock) => {
    try {
      setsymbolValue(stock);
      setLoading(true);
      const response = await axios.get(`/null/count-nulls-symbols-cells`, {
        params: {
          selectedTable: tableValue,
          selectedColumn: columValue,
          selectedStock: stock,
        },
      });
      setRowsData(response.data.data); // Assuming the data is in response.data.data
      await loadOptions(columnsMap[tableValue]);
      setShowRowsModal(true);
    } catch (error) {
      if (error.response && error.response.status === 400) {
        toast.error(error.response.data.message);
      } else {
        console.error("Error fetching data", error);
      }
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleCloseRowsModal = () => {
    setInputValue("");
    setShowRowsModal(false);
    setRowsData(null);
    setSelectedFile(null);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInsertValue = async (id) => {
    try {
      setLoading(true);
      if (
        inputValue === "" ||
        inputValue === null ||
        inputValue === undefined
      ) {
        return toast.error(`Input value cannot be blank`);
      }
      const response = await axios.patch(
        `/null/replace-null`,
        { value: inputValue },
        {
          params: {
            selectedTable: tableValue,
            selectedColumn: columValue,
            id: id,
          },
        }
      );
      toast.success(response.data.status);
      // Optionally, you can refetch the rowsData or update the state to reflect the changes
      fetchData();
      handleViewMoreColumn(tableValue, columValue);
    } catch (error) {
      toast.error(error.response.data.message);
      console.error("Error inserting value", error);
    } finally {
      handleCloseRowsModal();
      setInputValue("");
      setLoading(false);
    }
  };

  const handleConfirmInsert = (id) => {
    const isConfirmed = window.confirm(
      `Are you sure you want to insert "${inputValue}" into ${tableValue} (${columValue} column) where the id is ${id}?`
    );
    if (isConfirmed) {
      handleInsertValue(id);
    } else {
      setInputValue("");
      handleCloseRowsModal();
    }
  };

  const handleFileChange = (e, id) => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type;
      const validImageTypes = ["image/jpeg", "image/png"];

      if (!validImageTypes.includes(fileType)) {
        toast.error("Please upload a valid image file (JPEG or PNG).");
        setSelectedFile(null);
        return;
      }
    }
    setSelectedFile(e.target.files[0]);
    setSelectedFileId(id);
  };

  const handleIconInsert = async () => {
    if (!selectedFile || selectedFileId === null) {
      toast.error("No file selected or ID missing");
      return;
    }

    const formData = new FormData();
    formData.append("file", selectedFile);

    try {
      setLoading(true);
      const response = await axios.patch(`/null/replace-null`, formData, {
        params: {
          selectedTable: tableValue,
          selectedColumn: columValue,
          id: selectedFileId,
        },
      });
      toast.success(response.data.status);
      // Optionally, you can refetch the rowsData or update the state to reflect the changes
      fetchData(); // Assuming you have a function to refetch the data
      handleViewMoreColumn(tableValue, columValue);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error uploading file");
      console.error("Error inserting value", error);
    } finally {
      handleCloseRowsModal();
      setSelectedFile(null);
      setSelectedFileId(null);
      setLoading(false);
    }
  };

  const loadOptions = async (cols) => {
    const newOptions = {};

    for (const column of cols) {
      if (tables.includes(column)) {
        const data = await fetchDistinctNames(column);
        if (data?.data) {
          newOptions[column] = data.data;
        }
      }
    }

    setOptions(newOptions); // Update options state with fetched data
  };

  return (
    <div className="container mt-5">
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <div className="row">
        {data.map((table) => (
          <div className="col-md-4 mb-4" key={table.name}>
            <Card>
              <Card.Body>
                <Card.Title>{table.name}</Card.Title>
                <Card.Text>Count of nulls: {table.count}</Card.Text>
                <Button variant="dark" onClick={() => handleViewMore(table)}>
                  View More
                </Button>
              </Card.Body>
            </Card>
          </div>
        ))}
      </div>
      {selectedTable && (
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>{`Columns and Nulls in ${selectedTable.name} Table`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Column</th>
                  <th>Null Count</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {selectedTable.data.map((col, index) => (
                  <tr key={index}>
                    <td>{col.column}</td>
                    <td>{col.nullCount}</td>
                    <td>
                      <Button
                        onClick={() =>
                          handleViewMoreColumn(selectedTable.name, col.column)
                        }
                        variant="dark"
                      >
                        View More
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {newModalData && (
        <Modal show={showNewModal} onHide={handleCloseNewModal}>
          <Modal.Header closeButton>
            <Modal.Title>{`Count of Nulls in ${columValue} in ${tableValue} Table`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {newModalData &&
                    Object.keys(newModalData[0]).map((key, index) => (
                      <th key={index}>{key}</th>
                    ))}
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {newModalData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                    <td>
                      <Button
                        onClick={() =>
                          handleViewRows(
                            Object.values(row)[0] // Assuming the first value is the stock
                          )
                        }
                        variant="dark"
                      >
                        View Rows
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseNewModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {rowsData && (
        <Modal show={showRowsModal} onHide={handleCloseRowsModal}>
          <Modal.Header closeButton>
            <Modal.Title>{`Nulls of ${symbolValue} in ${columValue} in ${tableValue} Table`}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  {rowsData &&
                    Object.keys(rowsData[0]).map((key, index) => (
                      <th key={index}>{key}</th>
                    ))}
                  <th>Insert {columValue}</th>
                </tr>
              </thead>
              <tbody>
                {rowsData.map((row, index) => (
                  <tr key={index}>
                    {Object.values(row).map((value, i) => (
                      <td key={i}>{value}</td>
                    ))}
                    <td>
                      {columValue === "icon" ? (
                        <>
                          <input
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={(e) => handleFileChange(e, row.id)}
                          />
                          <Button
                            variant="dark"
                            onClick={() => handleIconInsert(row.id)}
                          >
                            Insert Icon
                          </Button>
                        </>
                      ) : tables.includes(columValue) ? (
                        <>
                          <select
                            className="form-control"
                            onChange={handleInputChange}
                          >
                            <option value="">
                              Select {tableNames[columValue]}
                            </option>
                            {options[columValue]?.map((newRow) => (
                              <option key={newRow.id} value={newRow.id}>
                                {newRow.name}
                              </option>
                            ))}
                          </select>
                          <Button
                            variant="dark"
                            onClick={() => handleConfirmInsert(row.id)}
                          >
                            Insert
                          </Button>
                        </>
                      ) : dates.includes(columValue) ? (
                        <input
                          type="datetime-local"
                          onChange={handleInputChange}
                        />
                      ) : numericals.includes(columValue) ? (
                        <input type="number" onChange={handleInputChange} />
                      ) : (
                        <>
                          <input
                            type="text"
                            value={inputValue}
                            onChange={handleInputChange}
                          />
                          <Button
                            variant="dark"
                            onClick={() => handleConfirmInsert(row.id)}
                          >
                            Insert
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseRowsModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      <ToastContainer />
    </div>
  );
};

export default HandleNulls;
