import React, { useEffect, useState } from "react";
import axios from "../../api/axiosConfig";
// import Loader from "../../components/Loader/Loader"; // Import your loader component
// import InsertModal from "./../../components/Popups/InsertRow";
// import { ToastContainer, toast } from "react-toastify";
// import DynamicTable from "../../components/MUI Table/DynamicTable";
// import SymbolSearch from "../../components/Popups/SymbolSearch";
import NewsCard from "../../components/News Card/News Card";
import {
  Box,
  Grid,
  Pagination,
  Select,
  MenuItem,
  Button,
  InputLabel,
  FormControl,
  Checkbox,
  ListItemText,
} from "@mui/material";

function News() {
  const [newsData, setNewsData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [symbols, setSymbols] = useState([]);
  const [selectedSymbols, setSelectedSymbols] = useState([]);

  // Fetch distinct symbols in useEffect
  useEffect(() => {
    const fetchSymbols = async () => {
      try {
        const response = await axios.get("/news-notifs/get-news-symbols/");
        setSymbols(response.data.data);
      } catch (error) {
        console.error("Error fetching symbols:", error);
      }
    };

    fetchSymbols();
  }, []);

  const fetchNewsData = async (value) => {
    try {
      const response = await axios.post("/news-notifs/fetch-news/", {
        page: value,
        limit: 10,
        symbols: selectedSymbols, // Pass selected symbols here (array)
      });
      setNewsData(response.data.data);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching news data:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    fetchNewsData(value);
  };

  const handleSymbolChange = (event) => {
    const value = event.target.value;

    if (value.includes("all")) {
      setSelectedSymbols([]); // Clear when "All" is selected
    } else {
      setSelectedSymbols(value);
    }
  };

  return (
    <>
      <Box sx={{ marginBottom: "20px" }}>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Filters</h4>
          </div>
          <div className="card-body">
            <Box sx={{ width: "300px" }}>
              <FormControl fullWidth>
                <InputLabel>Select Symbols</InputLabel>
                <Select
                  multiple
                  value={selectedSymbols}
                  onChange={handleSymbolChange}
                  renderValue={(selected) =>
                    selected
                      .map((symbolId) => {
                        const symbol = symbols.find(
                          (sym) => sym.id === symbolId
                        );
                        return symbol ? symbol.name : "";
                      })
                      .join(", ")
                  }
                >
                  <MenuItem value="all">
                    <em>All</em>
                  </MenuItem>
                  {symbols.map((symbol) => (
                    <MenuItem key={symbol.id} value={symbol.id}>
                      <Checkbox
                        checked={selectedSymbols.indexOf(symbol.id) > -1}
                      />
                      <ListItemText primary={symbol.name} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Button
              variant="contained"
              onClick={() => fetchNewsData(1)}
              sx={{ backgroundColor: "black", color: "white" }}
            >
              Fetch
            </Button>
          </div>
        </div>
      </Box>

      {/* News Cards Box */}
      <div className="row equal-height">
        <div className="col-md-12 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">News</h4>
            </div>
            <div className="card-body">
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
              <Grid
                container
                spacing={2}
                sx={{ display: "flex", flexWrap: "wrap", gap: "16px" }}
              >
                {newsData.map((newsItem) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={newsItem.id}>
                    <NewsCard newsItem={newsItem} />
                  </Grid>
                ))}
              </Grid>
              <Pagination
                count={totalPages}
                page={page}
                onChange={handlePageChange}
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  justifyContent: "center",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default News;
