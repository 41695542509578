import React from "react";
import { Link } from "react-router-dom";
import "./Sidebar.css"; // You can use this to add styles to your sidebar
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faTimes,
  faHome,
  faTable,
  faPlus,
  faEdit,
  faCheck,
  faSignOutAlt,
  faUser,
  faBell,
  faNewspaper,
} from "@fortawesome/free-solid-svg-icons";
import { signOut } from "aws-amplify/auth";

function Sidebar({ isOpen, toggleSidebar }) {
  const handleSignOut = async () => {
    try {
      await signOut();
      window.location.reload(); // Reload the page to update the authentication state
    } catch (error) {
      console.log("Error signing out: ", error);
    }
  };
  return (
    <div className={`sidebar ${isOpen ? "open" : "closed"}`}>
      <button className="toggle-button" onClick={toggleSidebar}>
        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
      </button>
      <ul>
        <li>
          <Link to="/">
            <FontAwesomeIcon icon={faHome} />
            Home
          </Link>
        </li>
        <li>
          <Link to="/compareTables">
            <FontAwesomeIcon icon={faTable} />
            Compare Tables
          </Link>
        </li>
        <li>
          <Link to="/HandleNulls">
            <FontAwesomeIcon icon={faPlus} />
            Handling Nulls
          </Link>
        </li>
        <li>
          <Link to="/InsertandEdits">
            <FontAwesomeIcon icon={faEdit} />
            Inserts and Edits
          </Link>
        </li>
        <li>
          <Link to="/Recommendation">
            <FontAwesomeIcon icon={faCheck} />
            Recommendations
          </Link>
        </li>
        <li>
          <Link to="/Notifications">
            <FontAwesomeIcon icon={faBell} />
            Notifications
          </Link>
        </li>
        <li>
          <Link to="/News">
            <FontAwesomeIcon icon={faNewspaper} />
            News
          </Link>
        </li>
        <li>
          <Link to="/AdminDashboard">
            <FontAwesomeIcon icon={faUser} />
            Admin Dashboard
          </Link>
        </li>
        <li>
          <button onClick={handleSignOut} className="sign-out-button">
            <FontAwesomeIcon icon={faSignOutAlt} />
            Sign Out
          </button>
        </li>
      </ul>
    </div>
  );
}

export default Sidebar;
