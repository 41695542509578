import React, { useState, useEffect } from "react";
import axios from "../../api/axiosConfig";
import MUISimpleDashboardCard from "../../components/SimpleDashboardCard/MUI";
import { FaUsers } from "react-icons/fa";
import { ToastContainer, toast } from "react-toastify";
import {
  Grid,
  Box,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  TextField,
  Card,
  CardMedia,
  Typography,
  CardActions,
  CardContent,
} from "@mui/material";
import { Modal, Button } from "react-bootstrap"; // Import Bootstrap components
import DynamicTable from "../../components/MUI Table/DynamicTable";
import "./AdminDashboard.css";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import SingleLineChart from "../../components/Charts/Single Line Chart/SingleLineChart";
import PieChart from "../../components/Charts/Pie Chart/PieChart";

function Dashboard() {
  const [loading, setLoading] = useState(false); // State for loading
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [specificData, setspecificData] = useState([]);
  const [uniqueAdmins, setUniqueAdmins] = useState([]);
  const [uniqueAssets, setUniqueAssets] = useState([]);
  const [uniqueTables, setUniqueTables] = useState([]);
  const [uniqueUsers, setUniqueUsers] = useState([]);
  const [selectedAdmin, setselectedAdmin] = useState("");
  const [selectedAsset, setselectedAsset] = useState("");
  const [selectedTable, setselectedTable] = useState("");
  const [selectedUser, setselectedUser] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lineData, setlineData] = useState([]);
  const [pieData, setpieData] = useState([]);
  const [admins, setadmins] = useState([]);
  const [emailContent, setEmailContent] = useState({});
  const [showInput, setShowInput] = useState({});
  const [newAdminEmail, setNewAdminEmail] = useState("");

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/admin/get-all-logs");
      if (response.data.status === "successful") {
        const allData = response.data.data.flatMap((item) => item.data);

        const uniqueAdmins = Array.from(
          new Map(
            allData
              .filter((item) => item.acting_admin_id && item["Acting Admin"])
              .map((item) => [item.acting_admin_id, item["Acting Admin"]])
          ).entries()
        );

        const uniqueAssets = Array.from(
          new Map(
            allData
              .filter(
                (item) => item.affected_asset_id && item["Affected Asset"]
              )
              .map((item) => [item.affected_asset_id, item["Affected Asset"]])
          ).entries()
        );

        const uniqueTables = Array.from(
          new Set(
            allData
              .map((item) => item["Affected Table"])
              .filter((table) => table && table.trim() !== "")
          )
        ).map((table) => [table, table]);

        const uniqueUsers = Array.from(
          new Map(
            allData
              .filter((item) => item.affected_user_id && item["Affected User"])
              .map((item) => [item.affected_user_id, item["Affected User"]])
          ).entries()
        );

        const pie = response.data.data.map((item) => ({
          name: item.name,
          count: item.data.length,
        }));

        setUniqueAdmins(uniqueAdmins);
        setUniqueAssets(uniqueAssets);
        setUniqueTables(uniqueTables);
        setUniqueUsers(uniqueUsers);

        setData(response.data.data);
        setlineData(response.data.lineData);
        setpieData(pie);

        setadmins(response.data.admins);
      } else {
        console.error("Error fetching data: ", response.data);
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditTable = (data) => {
    const filteredData = data.map((item) => {
      const date = new Date(item.Date);
      const day = date.getDate();

      // Determine the ordinal suffix for the day
      const suffixes = ["th", "st", "nd", "rd"];
      const v = day % 100;
      const dayWithSuffix =
        day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);

      const formattedDate = `${date.toLocaleString("en-US", {
        month: "long",
      })} ${dayWithSuffix}, ${date.getFullYear()}`;

      return {
        Date: formattedDate,
        "Acting Admin": item["Acting Admin"],
        Action: item.Action,
        "Affected Asset": item["Affected Asset"],
        "Affected Table": item["Affected Table"],
        "Affected User": item["Affected User"],
      };
    });

    setspecificData(filteredData);
    setShowModal(true); // Show the popup when data is available
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setspecificData([]);
  };

  const handleFetchFunction = async () => {
    try {
      setLoading(true);
      // Make the request to /admin/filter-logs with query parameters
      const response = await axios.get("/admin/filter-logs", {
        params: {
          admin_id: selectedAdmin || null,
          affected_asset: selectedAsset || null,
          affected_user: selectedUser || null,
          affected_table: selectedTable || null,
          startDate: startDate || null,
          endDate: endDate || null,
        },
      });

      // Check if the response contains the data
      if (response.data && response.data.data) {
        // Update the state with the response data
        setData(response.data.data);
        setlineData(response.data.lineData);

        const pie = response.data.data.map((item) => ({
          name: item.name,
          count: item.data.length,
        }));

        setpieData(pie);
      } else {
        // Handle cases where the response does not contain the expected data
        console.log("No data found in response");
      }
    } catch (error) {
      // Handle any errors that occur during the request
      console.error("Error fetching data:", error);
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleResetFunction = () => {
    setStartDate("");
    setEndDate("");
    setselectedAdmin("");
    setselectedAsset("");
    setselectedTable("");
    setselectedUser("");
    fetchData();
  };

  const handleSendEmailClick = (id) => {
    setEmailContent({});
    setShowInput((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const handleInputChange = (id, value) => {
    setEmailContent((prev) => ({ ...prev, [id]: value }));
  };

  const handleSendEmail = async (id, email) => {
    try {
      const response = await axios.post("/admin/email", {
        email: email,
        content: emailContent[id] || "",
      });

      // Check if the response contains the data
      if (response.status === 200) {
        toast.success("Email sent successfully!");
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setShowInput({});
      setEmailContent({});
    }
  };

  const handleViewLogs = async (id) => {
    try {
      setLoading(true);
      const response = await axios.get("/admin/get-specific-logs", {
        params: {
          admin_id: id,
          startDate,
          endDate,
        },
      });

      // Check if the response contains the data
      if (response.data && response.data.data) {
        // Update the state with the response data
        handleEditTable(response.data.data);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
    } finally {
      setLoading(false);
      setStartDate("");
      setEndDate("");
    }
  };

  const handleAddAdmin = () => {
    if (window.confirm("Are you sure you want to add this admin?")) {
      setLoading(true);
      axios
        .post("/admin/add-admin/", { email: newAdminEmail })
        .then((response) => {
          // Handle success
          setNewAdminEmail(""); // Clear the input field
          fetchData();
          toast.success("Admin added successfully");
        })
        .catch((error) => {
          // Handle error
          console.error("Error adding admin:", error);
          if (error.response?.data?.message)
            toast.error(error.response.data.message);
          setNewAdminEmail("");
        });
    }
    setLoading(false);
  };

  const handleDisableAdmin = (id) => {
    console.log(id);
    if (window.confirm("Are you sure you want to disable this admin?")) {
      setLoading(true);
      axios
        .delete("/admin/disable-admin/", { params: { admin_id: id } })
        .then((response) => {
          // Handle success
          fetchData();
          toast.success("Admin disabled successfully");
        })
        .catch((error) => {
          // Handle error
          console.error("Error adding admin:", error);
          if (error.response?.data?.message)
            toast.error(error.response.data.message);
        });
    }
    setLoading(false);
  };

  return (
    <div>
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <Box>
        <div className="card h-100">
          <div className="card-header">
            <h4 className="mb-0">Admin Logs</h4>
          </div>
          <div className="card-body">
            {/* Dropdowns Row */}
            <Grid
              container
              spacing={2}
              marginBottom={2}
              marginTop={0.5}
              justifyContent="center"
            >
              <FormControl
                size="small"
                variant="outlined"
                style={{
                  minWidth: 120,
                  margin: "0 8px",
                  height: "56px",
                  marginTop: "8px",
                }}
              >
                <TextField
                  label="Start Date"
                  type="date"
                  value={startDate || ""}
                  onChange={(e) => setStartDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </FormControl>
              <FormControl
                size="small"
                variant="outlined"
                style={{
                  minWidth: 120,
                  margin: "0 8px",
                  height: "56px",
                  marginTop: "8px",
                }}
              >
                <TextField
                  label="End Date"
                  type="date"
                  value={endDate || ""}
                  onChange={(e) => setEndDate(e.target.value)}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                />
              </FormControl>
              <FormControl
                size="small"
                variant="outlined"
                style={{
                  minWidth: 120,
                  margin: "0 8px",
                  height: "56px",
                  marginTop: "8px",
                }}
              >
                <InputLabel id="admin-select-label">Admin</InputLabel>
                <Select
                  labelId="admin-select-label"
                  value={selectedAdmin || ""} // State to manage selected value
                  onChange={(e) => setselectedAdmin(e.target.value)} // Handler to update the state
                  label="Admin"
                  style={{ height: "100%" }}
                >
                  <MenuItem value="">Admin</MenuItem>
                  {uniqueAdmins.map(([id, name]) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                size="small"
                variant="outlined"
                style={{
                  minWidth: 120,
                  margin: "0 8px",
                  height: "56px",
                  marginTop: "8px",
                }}
              >
                <InputLabel id="asset-select-label">Asset</InputLabel>
                <Select
                  labelId="asset-select-label"
                  label="Affected Asset"
                  style={{ height: "100%" }}
                  value={selectedAsset || ""} // State to manage selected value
                  onChange={(e) => setselectedAsset(e.target.value)}
                >
                  <MenuItem value="">Asset</MenuItem>
                  {uniqueAssets.map(([id, name]) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                size="small"
                variant="outlined"
                style={{
                  minWidth: 120,
                  margin: "0 8px",
                  height: "56px",
                  marginTop: "8px",
                }}
              >
                <InputLabel id="table-select-label">Table</InputLabel>
                <Select
                  labelId="table-select-label"
                  label="Affected Table"
                  style={{ height: "100%" }}
                  value={selectedTable || ""} // State to manage selected value
                  onChange={(e) => setselectedTable(e.target.value)}
                >
                  <MenuItem value="">Table</MenuItem>
                  {uniqueTables.map(([value, label]) => (
                    <MenuItem key={value} value={value}>
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <FormControl
                size="small"
                variant="outlined"
                style={{
                  minWidth: 120,
                  margin: "0 8px",
                  height: "56px",
                  marginTop: "8px",
                }}
              >
                <InputLabel id="user-select-label">User</InputLabel>
                <Select
                  labelId="user-select-label"
                  label="Affected User"
                  style={{ height: "100%" }}
                  value={selectedUser || ""} // State to manage selected value
                  onChange={(e) => setselectedUser(e.target.value)}
                >
                  <MenuItem value="">User</MenuItem>
                  {uniqueUsers.map(([id, name]) => (
                    <MenuItem key={id} value={id}>
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  (!startDate || !endDate) &&
                  !selectedAdmin &&
                  !selectedAsset &&
                  !selectedTable &&
                  !selectedUser
                }
                onClick={handleFetchFunction}
                style={{
                  height: "56px", // Match height of FormControl
                  margin: "0 8px",
                  backgroundColor: "black",
                  color: "white",
                  marginTop: "8px",
                }}
              >
                Fetch
              </Button>
              <Button
                variant="contained"
                color="primary"
                disabled={
                  (!startDate || !endDate) &&
                  !selectedAdmin &&
                  !selectedAsset &&
                  !selectedTable &&
                  !selectedUser
                }
                onClick={handleResetFunction}
                style={{
                  height: "56px", // Match height of FormControl
                  margin: "0 8px",
                  backgroundColor: "black",
                  color: "white",
                  marginTop: "8px",
                }}
              >
                Reset
              </Button>
            </Grid>

            {/* Cards */}
            <Grid container spacing={2} justifyContent="center">
              {data.map((row, index) => (
                <MUISimpleDashboardCard
                  key={index}
                  icon={<FaUsers />}
                  value={row.length}
                  text={row.name}
                  iconBgColor="blue"
                  clickFunction={() => handleEditTable(row.data)}
                />
              ))}
            </Grid>
          </div>
        </div>
      </Box>
      {/* charts */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          {/* Line Chart*/}
          <div className="col-md-8 mb-4">
            <div className="card h-100">
              <div className="card-header">
                <h4 className="mb-0">Activities By Days</h4>
              </div>
              <div className="card-body">
                <SingleLineChart data={lineData} lineColor="blue" />
              </div>
            </div>
          </div>
          {/* Pie Chart*/}
          <div className="col-md-4 mb-4">
            <div className="card h-100">
              <div className="card-header">
                <h4 className="mb-0">Activities By Actions</h4>
              </div>
              <div className="card-body">
                <PieChart
                  data={
                    pieData
                  } /* check the component for the colors if you need */
                  colors={[
                    "blue",
                    "#c5c5c7",
                    "pink",
                    "#04ff00",
                    "red",
                    "yellow",
                  ]}
                />
              </div>
            </div>
          </div>
        </div>
      </Box>
      {/* admins */}
      <Box style={{ marginTop: "20px" }}>
        <div className="row equal-height">
          {/* Line Chart*/}
          <div className="col-md-12 mb-4">
            <div className="card h-100">
              <div className="card-header">
                <h4 className="mb-0">Admins</h4>
              </div>
              <div
                className="card-body"
                style={{ display: "flex", flexWrap: "wrap", gap: "16px" }}
              >
                {admins.map((admin) => (
                  <Card key={admin.id} sx={{ maxWidth: 345, marginBottom: 2 }}>
                    <CardMedia
                      sx={{ height: 200 }}
                      image={admin.icon ? admin.icon : "/admin.jpg"}
                      title={admin.name}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {admin.name}
                      </Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        className="btn btn-dark btn-sm"
                        onClick={() => handleSendEmailClick(admin.id)}
                      >
                        Send Email
                      </Button>
                      <Button
                        className="btn btn-dark btn-sm"
                        onClick={(e) => handleViewLogs(admin.id)}
                      >
                        View Logs
                      </Button>
                      <Button
                        className="btn btn-dark btn-sm"
                        onClick={() => handleDisableAdmin(admin.id)}
                      >
                        Disable
                      </Button>
                    </CardActions>
                    {showInput[admin.id] && (
                      <div style={{ padding: "0 16px 16px" }}>
                        <TextField
                          fullWidth
                          label="Email Content"
                          variant="outlined"
                          size="small"
                          value={emailContent[admin.id] || ""}
                          onChange={(e) =>
                            handleInputChange(admin.id, e.target.value)
                          }
                        />
                        <Button
                          className="btn btn-dark btn-sm"
                          onClick={() => handleSendEmail(admin.id, admin.email)}
                          style={{ marginTop: "8px" }}
                          disabled={!emailContent[admin.id]?.trim()}
                        >
                          Send
                        </Button>
                      </div>
                    )}
                  </Card>
                ))}
                <Card sx={{ maxWidth: 345, marginBottom: 2 }}>
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      Add New Admin
                    </Typography>
                    <TextField
                      fullWidth
                      label="Email"
                      variant="outlined"
                      size="small"
                      value={newAdminEmail}
                      onChange={(e) => setNewAdminEmail(e.target.value)}
                    />
                  </CardContent>
                  <CardActions>
                    <Button
                      className="btn btn-dark btn-sm"
                      onClick={handleAddAdmin}
                      disabled={!newAdminEmail?.trim()}
                    >
                      Submit
                    </Button>
                  </CardActions>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </Box>
      {/* Modal Popup for Symbols */}
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        dialogClassName="custom-modal"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="table-container">
            <DynamicTable className="dynamic-table" data={specificData} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
    </div>
  );
}

export default Dashboard;
