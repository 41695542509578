import React from "react";
import { Line } from "react-chartjs-2";
import { useTheme } from "@mui/material";
import {
  Chart as ChartJS,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  Tooltip,
  Legend
);

const SingleLineChart = ({ data, lineColor }) => {
  const theme = useTheme();

  // Prepare the data for the chart
  const chartData = {
    labels: data.map((item) => item.date),
    datasets: [
      {
        label: "Count",
        data: data.map((item) => item.count),
        borderColor: lineColor || theme.palette.primary.main,
        backgroundColor: `${lineColor || theme.palette.primary.main}33`, // 33 for transparency
        borderWidth: 2,
        fill: true,
        tension: 0.3, // This makes the line smoother
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false, // Removed legend
      },
      tooltip: {
        enabled: true,
        mode: "index",
        intersect: false,
        backgroundColor: theme.palette.background.paper,
        titleColor: theme.palette.text.primary,
        bodyColor: theme.palette.text.primary,
      },
    },
    scales: {
      x: {
        ticks: {
          color: theme.palette.text.primary,
          font: { size: 14, weight: "bold" }, // Larger and bold labels
          maxTicksLimit: 15,
        },
        grid: { display: false },
      },
      y: {
        ticks: {
          color: theme.palette.text.primary,
          font: { size: 14, weight: "bold" }, // Larger and bold labels
        },
        grid: { display: false },
      },
    },
  };

  return <Line data={chartData} options={options} />;
};

export default SingleLineChart;
