import axios from "./../../api/axiosConfig";

const fetchDistinctNames = async (column) => {
  try {
    const response = await axios.get(`/compare/distinct-names`, {
      params: {
        selectedTable: column,
      },
    });

    return response.data;
  } catch (error) {
    console.error("Error while querying data", error);
    return null;
  } finally {
  }
};

export { fetchDistinctNames }; // Use ES6 export
