import React from "react";
// import React, { useEffect, useState } from "react";
// import axios from "../../api/axiosConfig";
// import Loader from "../../components/Loader/Loader"; // Import your loader component
// import InsertModal from "./../../components/Popups/InsertRow";
// import { ToastContainer, toast } from "react-toastify";
// import DynamicTable from "../../components/MUI Table/DynamicTable";
// import SymbolSearch from "../../components/Popups/SymbolSearch";

function Notifications() {
  return (
    <div>
      <h1>Notifications</h1>
      <p>Welcome to the home page!</p>
    </div>
  );
}

export default Notifications;
