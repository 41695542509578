import React, { useState } from "react";
import axios from "../../api/axiosConfig";
import Loader from "../../components/Loader/Loader"; // Import your loader component
import { ToastContainer, toast } from "react-toastify";
import { Modal, Button, Table } from "react-bootstrap"; // Import Bootstrap components
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import "./CompareTables.css"; // Import custom CSS for styling
import InsertModal from "./../../components/Popups/InsertRow";

function CompareTables() {
  const [loading, setLoading] = useState(false); // State for loading
  const [baseTableSelection, setBaseTableSelection] = useState("");
  const [subjectTableSelection, setSubjectTableSelection] = useState("");
  const [data, setData] = useState(null);
  const [showInsertModal, setShowInsertModal] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [formData, setFormData] = useState({}); // State for form data
  const [selectedFile, setSelectedFile] = useState(null);
  const [completeTable, setcompleteTable] = useState("");
  const [minYear, setminYear] = useState(null);
  const [maxYear, setmaxYear] = useState(null);
  const [completeData, setcompleteData] = useState([]);
  const [showCompleteModal, setShowCompleteModal] = useState(false);
  const [completeID, setcompleteID] = useState("");

  const handleBaseTableChange = (event) => {
    setBaseTableSelection(event.target.value);
    // Reset subject table selection when base table selection changes
    setSubjectTableSelection("");
  };

  const handleSubjectTableChange = async (event) => {
    try {
      if (baseTableSelection === event.target.value) {
        setSubjectTableSelection("");
        return toast.error(`Select a mix of different tables`);
      }
      setSubjectTableSelection(event.target.value);
      setLoading(true);
      const response = await axios.get(`/compare/compare-tables`, {
        params: {
          baseTable: baseTableSelection,
          subjectTable: event.target.value,
        },
      });
      if (response.status === 200) {
        // Check for a successful response
        setData(response.data.data);
        setShowModal(true); // Show the popup when data is available
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleCloseModal = () => {
    setSubjectTableSelection("");
    setBaseTableSelection("");
    setShowModal(false);
    setSelectedFile(null);
  };

  // Update handleInsertClick to set columns
  const handleInsertClick = async (symbol) => {
    setSelectedSymbol(symbol);
    setFormData({});
    setShowInsertModal(true);
  };

  const handleInsertModalClose = () => {
    setShowInsertModal(false);
    setSelectedFile(null);
  };

  const handleInputChange = (column, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [column]: value,
    }));
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const fileType = e.target.files[0].type;
      const validImageTypes = ["image/jpeg", "image/png"];

      if (!validImageTypes.includes(fileType)) {
        toast.error("Please upload a valid image file (JPEG or PNG).");
        setSelectedFile(null);
        return;
      }
    }
    setSelectedFile(e.target.files[0]);
  };

  const handleInsertRow = async () => {
    try {
      setLoading(true);
      if (
        Object.keys(formData).length === 0 ||
        Object.values(formData).every((value) => value === "")
      ) {
        return toast.error(`You cannot send an empty data`);
      }

      const form = new FormData();
      form.append(
        "selectedTable",
        subjectTableSelection.length > 0 ? subjectTableSelection : completeTable
      );
      form.append("symbol", selectedSymbol);
      Object.keys(formData).forEach((key) => {
        form.append(`data[${key}]`, formData[key]);
      });

      // Handle file input separately
      if (selectedFile && selectedFile instanceof File) {
        form.append("file", selectedFile);
      }

      const response = await axios.post("/insert/insert-single", form, {
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (response.status === 200) {
        toast.success("Data inserted successfully");

        if (subjectTableSelection || subjectTableSelection !== "")
          setData(data.filter((item) => item.name !== selectedSymbol));

        if (completeTable || completeTable !== "")
          setcompleteData(
            completeData.filter((item) => item.id !== completeID)
          );
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Error inserting row");
      console.error("Error inserting data", error);
    } finally {
      setShowInsertModal(false);
      setSelectedFile(null);
      setLoading(false);
    }
  };

  const handleCompleteTableChange = (event) => {
    setcompleteTable(event.target.value);
    setminYear("");
    setmaxYear("");
  };

  const handleCheckCompletion = async () => {
    try {
      if (maxYear < minYear) {
        return toast.error(`Min year cannot be greater than max year`);
      }
      if (minYear < 2015) {
        return toast.error(`Min year cannot be lower than 2015`);
      }
      if (maxYear > new Date().getFullYear()) {
        return toast.error(
          `Max year cannot be higher than ${new Date().getFullYear()}`
        );
      }
      setLoading(true);
      const response = await axios.get(`/compare/table-completion`, {
        params: {
          selectedTable: completeTable,
          minYear: minYear,
          maxYear: maxYear,
        },
      });
      if (response.status === 200) {
        // Check for a successful response
        setcompleteData(response.data.data);
        setShowCompleteModal(true); // Show the popup when data is available
      } else {
        toast.error(`Unexpected response code: ${response.status}`);
      }
    } catch (error) {
      if (error.response?.data?.message)
        toast.error(error.response.data.message);
      console.error("Error fetching data", error);
    } finally {
      setLoading(false); // Set loading to false after data is fetched
    }
  };

  const handleCloseCompleteModal = () => {
    setShowCompleteModal(false);
    setcompleteTable("");
    setminYear("");
    setmaxYear("");
  };

  return (
    <div className="container mt-5">
      {loading && (
        <div className="overlay">
          <Loader />
        </div>
      )}
      <div className="row equal-height">
        {/* Base Table Box */}
        <div className="col-md-6 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">Base Table</h4>
            </div>
            <div className="card-body">
              <p>
                The base table is simply the reference point. As in the table to
                check if its contents are missing in the other table.
              </p>
              <select
                className="form-control"
                value={baseTableSelection}
                onChange={handleBaseTableChange}
              >
                <option value="">Select an option</option>
                <option value="symbol">Symbol</option>
                <option value="company_information">Company Information</option>
                <option value="financials">Financials</option>
                <option value="dividend">Dividend</option>
                <option value="daily_prices">Daily Prices</option>
              </select>
            </div>
          </div>
        </div>

        {/* Subject Table Box */}
        <div className="col-md-6 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">Subject Table</h4>
            </div>
            <div className="card-body">
              <p>
                The subject table is the table you are checking for missing
                contents. As in how many contents in the base table are missing
                here?
              </p>
              <select
                className="form-control"
                value={subjectTableSelection}
                onChange={handleSubjectTableChange}
                disabled={!baseTableSelection}
              >
                <option value="">Select an option</option>
                <option value="company_information">Company Information</option>
                <option value="financials">Financials</option>
                <option value="dividend">Dividend</option>
                <option value="daily_prices">Daily Prices</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div className="row equal-height">
        {/* Completion Table Box */}
        <div className="col-md-12 mb-4">
          <div className="card h-100">
            <div className="card-header">
              <h4 className="mb-0">Check Completion Rate</h4>
            </div>
            <div className="card-body">
              <p>
                The purpose of this box is to check how complete the tables that
                deal with years are. For example, we need at least five unique
                years for each symbol in financials.
              </p>
              <div className="row">
                <div className="col-md-4">
                  <select
                    className="form-control"
                    value={completeTable}
                    onChange={handleCompleteTableChange}
                  >
                    <option value="">Select a table</option>
                    <option value="financials">Financials</option>
                    <option value="dividend">Dividend</option>
                    <option value="daily_prices">Daily Prices</option>
                  </select>
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    value={minYear || ""}
                    className="form-control"
                    placeholder="Lowest year"
                    min="2015"
                    max={new Date().getFullYear()}
                    onChange={(e) => setminYear(e.target.value)}
                    disabled={completeTable === ""}
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="number"
                    value={maxYear || ""}
                    className="form-control"
                    placeholder="Highest year"
                    min={minYear}
                    max={new Date().getFullYear()}
                    onChange={(e) => setmaxYear(e.target.value)}
                    disabled={!minYear}
                  />
                </div>
              </div>
              <div className="mt-3">
                <button
                  className="btn btn-dark"
                  onClick={handleCheckCompletion}
                  disabled={!maxYear}
                >
                  Check
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Modal Popup for Symbols */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Symbols in {baseTableSelection} but not in {subjectTableSelection}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            There are <b>{data ? data.length : 0} symbols</b> in{" "}
            {baseTableSelection} that are not in {subjectTableSelection}. Here
            are the first 20:
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Symbol</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.slice(0, 20).map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>
                      <Button
                        variant="dark"
                        onClick={() => handleInsertClick(item.name)}
                      >
                        Insert
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Insert Modal Popup */}
      <div>
        <InsertModal
          show={showInsertModal}
          handleClose={handleInsertModalClose}
          tableTitle={
            subjectTableSelection.length > 0
              ? subjectTableSelection
              : completeTable
          }
          selectedSymbol={selectedSymbol}
          handleFileChange={handleFileChange}
          handleInputChange={handleInputChange}
          handleInsertRow={handleInsertRow}
        />
      </div>

      {/* Show complete modal */}
      <Modal show={showCompleteModal} onHide={handleCloseCompleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            Completion of Symbols in{" "}
            {`${completeTable} from ${minYear} to ${maxYear}`}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            There are <b>{completeData ? completeData.length : 0} symbols</b> in{" "}
            {completeTable} that are do not have all the years from{" "}
            {`${minYear} to ${maxYear}`}. Here are the first 20:
          </p>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Company Name</th>
                <th>Symbol</th>
                <th>Missing Years</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {completeData &&
                completeData.slice(0, 20).map((item, index) => (
                  <tr key={index}>
                    <td>{item.name}</td>
                    <td>{item.symbol}</td>
                    <td>{item.year}</td>
                    <td>
                      <Button
                        variant="dark"
                        onClick={() => {
                          handleInsertClick(item.symbol);
                          setcompleteID(item.id);
                        }}
                      >
                        Insert
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCompleteModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <ToastContainer />
    </div>
  );
}

export default CompareTables;
