import React from "react";

import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  Box,
} from "@mui/material";

const MUISimpleDashboardCard = ({
  icon,
  value,
  text,
  iconBgColor,
  clickFunction,
}) => (
  <Card
    sx={{
      minWidth: 225,
      m: 2,
      borderRadius: 5, // Rounded edges
      border: "2px solid",
      borderColor: "dark", // Card border color
    }}
  >
    <CardContent>
      <Grid container alignItems="center">
        <Grid item>
          <Box
            sx={{
              backgroundColor: iconBgColor || "black",
              borderRadius: "25%", // Rounded edges for the icon container
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 50,
              height: 50,
              mr: 2,
            }}
          >
            <Box sx={{ fontSize: "1.7rem", color: "#fff" }}>{icon}</Box>
          </Box>
        </Grid>
        <Grid item>
          <Typography variant="h5" component="div">
            <strong>{value}</strong>
          </Typography>
          <Typography color="text.secondary">{text}</Typography>
        </Grid>
      </Grid>
      <Button
        size="small"
        variant="contained"
        sx={{ mt: 2, backgroundColor: "black", borderRadius: 2 }}
        onClick={clickFunction}
      >
        View Details
      </Button>
    </CardContent>
  </Card>
);

export default MUISimpleDashboardCard;
